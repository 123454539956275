import { useAuthStore } from '~/stores/auth';

export default defineNuxtRouteMiddleware(async (to) => {
  // Skip auth check for login page
  if (to.path === '/app/login') {
    return;
  }

  const authStore = useAuthStore();
  try {
    // Initialize auth status if not done yet
    if (!authStore.user) {
      await authStore.initAuth();
    }

    // If not logged in, redirect to login page
    if (!authStore.isLoggedIn) {
      return navigateTo('/app/login?state=unauthorized');
    }

    return;
  } catch (error) {
    console.error(error);
    return navigateTo('/app/login?state=error');
  }
});
